<template>
    <div>

        <template v-if="type === 'imageGalleryVertical' && swiperData" >

            <swiper style="max-height: 500px; height: 500px !important;" @slideChange="onSlideVerticalChangeEvent" :allow-touch-move="false" class="swiper-navigations swiper-multiple swiper-container-vertical swiper-vertical-class" :options="swiperOptionsVertical" ref="swiperVerticalTop" v-bind:class="[ { 'swiper-vertical-bottom-imageGallery': !isSwiperVerticalOnEnd && isSwiperVerticalOnBeg}, { 'swiper-vertical-top-imageGallery': !isSwiperVerticalOnBeg && isSwiperVerticalOnEnd}, { 'swiper-vertical-both-imageGallery': !isSwiperVerticalOnBeg && !isSwiperVerticalOnEnd} ]">
                <swiper-slide v-for="(item, index) in swiperData" :key="'thumbmail_' + item.itemId">
                    <b-img :src="item.thumbnail" @click="fullScreenImageVertical(item, index)" style="width: 100%; height: 100%; object-fit: cover; object-position: center;">
                    </b-img>
                </swiper-slide>
                <div slot="button-next" class="d-flex justify-content-center align-items-center" v-bind:class="[ { 'swiper-height-none': isSwiperVerticalOnEnd && !isSwiperVerticalOnBeg }, { 'swiper-button-next-vertical-both': !isSwiperVerticalOnEnd && !isSwiperVerticalOnBeg }, { 'swiper-button-next-vertical': !swiperLessThanWidthPicVertical } ]">
                    <div v-bind:class="[ { 'hide-content': isSwiperVerticalOnEnd }, { 'show-content': !isSwiperVerticalOnEnd }, { 'hide-content': swiperLessThanWidthPicVertical }, { 'd-none': swiperLessThanWidthPicVertical } ]">
                        +
                    </div>
                </div>
                <div slot="button-prev" class="d-flex justify-content-center align-items-center swiper-button-prev-vertical" v-bind:class="[ { 'swiper-height-none': isSwiperVerticalOnBeg && !isSwiperVerticalOnEnd }, { 'swiper-button-prev-vertical-both': !isSwiperVerticalOnEnd && !isSwiperVerticalOnBeg } ]">
                    <div v-bind:class="[ { 'hide-content': isSwiperVerticalOnBeg }, { 'show-content': !isSwiperVerticalOnBeg }, { 'hide-content': swiperLessThanWidthPicVertical } ]">
                        -
                    </div>
                </div>
            </swiper>
            

            <div v-if="modalShow"  style="position: fixed; top: 0; left: 0; z-index: 1000;" v-on:keydown.esc="close()" v-on:click="closeGallery">

                <div class="noselect d-flex justify-content-center align-items-center" style="background: rgba(0,0,0,0.65); height: 100vh; width: 100vw;" @touchstart="touchStart" @touchend="detectSwipe">
                    
                    <div>
                        <div v-for=" (item, id) in swiperData" :key="'image_' + item.itemId + '_' + id">
                            <b-img class="image-fullscreen-gallery" v-show="item.itemId == currentModalItem.itemId" :src="item.src" style="z-index: 999;" thumbnail fluid></b-img>
                            <div v-show="item.itemId == currentModalItem.itemId" class="text-bottom-right" style="color: white;">
                                {{id+1}}/{{swiperData.length}}
                            </div>
                        </div>

                       
                    </div>
                    
                </div>

                <!-- Close Button -->
                <i class="close-btn Close" v-on:click="close()" ></i>

                <!-- Left Button -->
                <svg v-if="swiperData && swiperData.length >= 2" class="more-arrows left d-none d-md-inline" v-on:click="nextImage('left')">
                    <polygon class="arrow-top" points="37.6,27.9 1.8,1.3 3.3,0 37.6,25.3 71.9,0 73.7,1.3 "/>
                    <polygon class="arrow-middle" points="37.6,45.8 0.8,18.7 4.4,16.4 37.6,41.2 71.2,16.4 74.5,18.7 "/>
                    <polygon class="arrow-bottom" points="37.6,64 0,36.1 5.1,32.8 37.6,56.8 70.4,32.8 75.5,36.1 "/>
                </svg>

                <!-- Right Button -->
                <svg v-if="swiperData && swiperData.length >= 2" class="more-arrows right  d-none d-md-inline" v-on:click="nextImage('right')">
                    <polygon class="arrow-top" points="37.6,27.9 1.8,1.3 3.3,0 37.6,25.3 71.9,0 73.7,1.3 "/>
                    <polygon class="arrow-middle" points="37.6,45.8 0.8,18.7 4.4,16.4 37.6,41.2 71.2,16.4 74.5,18.7 "/>
                    <polygon class="arrow-bottom" points="37.6,64 0,36.1 5.1,32.8 37.6,56.8 70.4,32.8 75.5,36.1 "/>
                </svg>
                
               
            </div>
        </template>

        <template v-if="(type === 'imageGallery' || type === 'webShopGallery') && swiperData" >
            <swiper @slideChange="onSlideChangeEvent" :allow-touch-move="false" class="swiper-navigations swiper-multiple mt-1" :options="swiperOptions" ref="swiperTop" v-bind:class="[ { 'navigation-swiper-right': !isSwiperOnEnd && isSwiperOnBeg}, { 'navigation-swiper-left': !isSwiperOnBeg && isSwiperOnEnd}, { 'navigation-swiper-both': !isSwiperOnBeg && !isSwiperOnEnd} ]">
                <swiper-slide v-for="(item, index) in swiperData" :key="getKeyForSwiper(item)">
                    <b-card v-if="type === 'imageGallery'" class="mb-0" @click="fullScreenImage(item, index)" :img-height="heightImg"  style="cursor: pointer;" overlay :img-src="`${item.thumbnail}`"/>
                    <b-card v-if="type === 'webShopGallery'" class="mb-0" @click="fullScreenImage(item, index)" img-height="150"  style="cursor: pointer;" overlay :img-src="'/api/user/v1/web_shop/product_image/500_' + item"/>
                </swiper-slide>
                <div slot="button-next" class="d-flex justify-content-center align-items-center " v-bind:class="[ { 'swiper-width-none': isSwiperOnEnd && !isSwiperOnBeg}, { 'swiper-button-next-both': !isSwiperOnEnd && !isSwiperOnBeg}, { 'swiper-button-next-image-gallery': !swiperLessThanWidthPic } ]">
                    <div v-bind:class="[ { 'hide-content': isSwiperOnEnd }, { 'show-content': !isSwiperOnEnd }, { 'd-none': swiperLessThanWidthPic } ]">
                        +
                    </div>
                </div>
              
                <div slot="button-prev" class="d-flex justify-content-center align-items-center swiper-button-prev-image-gallery" v-bind:class="[ { 'swiper-width-none': isSwiperOnBeg && !isSwiperOnEnd }, { 'swiper-button-prev-both': !isSwiperOnEnd && !isSwiperOnBeg } ]">
                    <div v-bind:class="[ { 'hide-content': isSwiperOnBeg }, { 'show-content': !isSwiperOnBeg } ]">
                        -
                    </div>
                </div>
                <div v-if="pagination" slot="pagination" class="swiper-pagination"/>
            </swiper>

            <div v-if="modalShow"  style="position: fixed; top: 0; left: 0; z-index: 1000;" v-on:keydown.esc="close()" v-on:click="closeGallery">

                <div class="noselect d-flex justify-content-center align-items-center" style="background: rgba(0,0,0,0.65); height: 100vh; width: 100vw;" @touchstart="touchStart" @touchend="detectSwipe">
                    
                    <div>
                        <div v-if="type === 'imageGallery'">
                            <div v-for=" (item, id) in swiperData" :key="'image_' + item.itemId + '_' + id">
                                <b-img class="image-fullscreen-gallery" v-show="item.itemId == currentModalItem.itemId" :src="item.src" style="z-index: 999;" thumbnail fluid></b-img>
                                <div v-show="item.itemId == currentModalItem.itemId" class="text-bottom-right" style="color: white;">
                                    {{id+1}}/{{swiperData.length}}
                                </div>
                            </div>
                        </div>

                        <div v-if="type === 'webShopGallery'">
                            <div v-for="(item, index) in swiperData" :key="'image_' + index">

                                <b-img  class="image-fullscreen-gallery" v-show="item == currentModalItem" :src="'/api/user/v1/web_shop/product_image/1000_' + item" style="z-index: 999;" thumbnail fluid></b-img>
                                <div v-show="item == currentModalItem" class="text-bottom-right" style="color: white;">
                                    {{index+1}}/{{swiperData.length}}
                                </div>
                            </div>
                        </div>

                    </div>
                    
                </div>

                <!-- Close Button -->
                <i class="close-btn Close" v-on:click="close()" ></i>

                <!-- Left Button -->
                <svg v-if="swiperData && swiperData.length >= 2" class="more-arrows left d-none d-md-inline" v-on:click="nextImage('left')">
                    <polygon class="arrow-top" points="37.6,27.9 1.8,1.3 3.3,0 37.6,25.3 71.9,0 73.7,1.3 "/>
                    <polygon class="arrow-middle" points="37.6,45.8 0.8,18.7 4.4,16.4 37.6,41.2 71.2,16.4 74.5,18.7 "/>
                    <polygon class="arrow-bottom" points="37.6,64 0,36.1 5.1,32.8 37.6,56.8 70.4,32.8 75.5,36.1 "/>
                </svg>

                <!-- Right Button -->
                <svg v-if="swiperData && swiperData.length >= 2" class="more-arrows right  d-none d-md-inline" v-on:click="nextImage('right')">
                    <polygon class="arrow-top" points="37.6,27.9 1.8,1.3 3.3,0 37.6,25.3 71.9,0 73.7,1.3 "/>
                    <polygon class="arrow-middle" points="37.6,45.8 0.8,18.7 4.4,16.4 37.6,41.2 71.2,16.4 74.5,18.7 "/>
                    <polygon class="arrow-bottom" points="37.6,64 0,36.1 5.1,32.8 37.6,56.8 70.4,32.8 75.5,36.1 "/>
                </svg>
                
               
            </div>
        </template>

        <template v-else-if="type === 'images'"> <!--where images in array and not stored in minio-->
            <swiper class="swiper-navigations swiper-multiple mt-1 w-100" :options="swiperOptions" ref="swiperTop" >
                <swiper-slide v-for="(item, index) in swiperData" :key="'img_' + index">
                    <b-card class="mb-0" @click="fullScreenImage(item, index)" img-height="150"  style="cursor: pointer;" overlay :img-src="item"/>
                </swiper-slide>
                <div slot="button-next" class="swiper-button-next"/>
                <div slot="button-prev" class="swiper-button-prev"/>
                <div v-if="pagination" slot="pagination" class="swiper-pagination"/>
            </swiper>

            <div v-if="modalShow"  style="position: fixed; top: 0; left: 0; z-index: 1000;" v-on:keydown.esc="close()" v-on:click="closeGallery">

                <div class="noselect d-flex align-items-center justify-content-center" style="background: rgba(0,0,0,0.65); height: 100vh; width: 100vw;" @touchstart="touchStart" @touchend="detectSwipe">

                    <div v-for="(item, index) in swiperData" :key="'image_' + index">
                        <b-img v-show="item == currentModalItem" :src="item" style="z-index: 999; max-width: 80vw; max-height: 80vh;" thumbnail fluid></b-img>
                    </div>

                </div>

                <!-- Close Button -->
                <i class="close-btn Close" v-on:click="close()" ></i>

                <!-- Left Button -->
                <svg v-if="swiperData && swiperData.length >= 2" class="more-arrows left" v-on:click="nextImage('left')">
                    <polygon class="arrow-top" points="37.6,27.9 1.8,1.3 3.3,0 37.6,25.3 71.9,0 73.7,1.3 "/>
                    <polygon class="arrow-middle" points="37.6,45.8 0.8,18.7 4.4,16.4 37.6,41.2 71.2,16.4 74.5,18.7 "/>
                    <polygon class="arrow-bottom" points="37.6,64 0,36.1 5.1,32.8 37.6,56.8 70.4,32.8 75.5,36.1 "/>
                </svg>

                <!-- Right Button -->
                <svg v-if="swiperData && swiperData.length >= 2" class="more-arrows right" v-on:click="nextImage('right')">
                    <polygon class="arrow-top" points="37.6,27.9 1.8,1.3 3.3,0 37.6,25.3 71.9,0 73.7,1.3 "/>
                    <polygon class="arrow-middle" points="37.6,45.8 0.8,18.7 4.4,16.4 37.6,41.2 71.2,16.4 74.5,18.7 "/>
                    <polygon class="arrow-bottom" points="37.6,64 0,36.1 5.1,32.8 37.6,56.8 70.4,32.8 75.5,36.1 "/>
                </svg>

            </div>
        </template>

        <div v-else-if="type === 'sale'">
            <swiper  class="swiper-navigations swiper-multiple mt-1" :options="swiperOptionsSale" >
                <swiper-slide class="swiper-sale" v-for="(data,index) in swiperData" :key="index" style="height: unset !important;">
                    <div style="padding: 4% 0 8% 0; height: 100% !important;">
                        <SaleCard :object="data" ribbon="TOP CENA"/>
                    </div>
                </swiper-slide>
                <div slot="button-next" class="swiper-button-next"/>
                <div slot="button-prev" class="swiper-button-prev"/>
            </swiper>
            <div v-if="pagination" slot="pagination" class="swiper-pagination"/>
        </div>
       

        <div v-else-if="type === 'realEstate'" style="position:relative;">
            <swiper  class="swiper-navigations swiper-multiple mt-1 swiper-buttons-on-outside" :options="swiperOptionsRealEstate">
                <swiper-slide v-for="item in swiperData" :key="item.id">
                    <RealEstateCard :realEstate="item" style=""/>
                </swiper-slide>
                <div slot="button-next" class="swiper-button-next">
                    <div class="swiper-right-hide-padding"></div>
                </div>
                <div slot="button-prev" class="swiper-button-prev">
                    <div class="swiper-left-hide-padding"></div>
                </div>
            </swiper>
            
            <div v-if="pagination" slot="pagination" class="swiper-pagination"/>
        </div>
    </div>
</template>

<script>
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
    import 'swiper/css/swiper.css'
    import SaleCard from '../Components/SaleCard'
    import RealEstateCard from '../Components/RealEstateCard'
    import { BImg, BCard } from 'bootstrap-vue'

    export default {
        components: {
            Swiper,
            SwiperSlide,
            SaleCard,
            RealEstateCard,
            BImg,
            BCard
        },
        props: {
            swiperData: {
                type: Array,
                required: true
            },
            pagination: {
                type: Boolean,
                required: true
            },
            type: {
                type: String,
                required: false,
                default: 'images'
            },
            heightImg:{
                type: Number,
                default: 100
            }
        },
        data() {
            return {
                swiperLessThanWidthPicVertical: false,
                swiperLessThanWidthPic: false,
                isSwiperVerticalOnEnd: false,
                isSwiperVerticalOnBeg: true,
                isSwiperOnEnd: false,
                isSwiperOnBeg: true,
                swiperOptionsVertical: {
                    direction: 'vertical',
                    slidesPerView: 3,
                    spaceBetween: 10,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    navigation: {
                        nextEl: '.swiper-button-next-vertical',
                        prevEl: '.swiper-button-prev-vertical'
                    },
                    
                    breakpoints: {
                        1024: {
                            slidesPerView: 3
                        },
                        768: {
                            slidesPerView: 3
                        },
                        640: {
                            slidesPerView: 1
                        },
                        320: {
                            slidesPerView: 1
                        }
                    }
                },
                swiperOptions: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    navigation: {
                        nextEl: '.swiper-button-next-image-gallery',
                        prevEl: '.swiper-button-prev-image-gallery'
                    },
                    
                    breakpoints: {
                        1024: {
                            slidesPerView: 3
                        },
                        768: {
                            slidesPerView: 2
                        },
                        640: {
                            slidesPerView: 2
                        },
                        320: {
                            slidesPerView: 1
                        }
                    }
                },
                swiperOptionsRealEstate: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    },
                    breakpoints: {
                        1024: {
                            slidesPerView: 3
                        },
                        768: {
                            slidesPerView: 2
                        },
                        640: {
                            slidesPerView: 1
                        },
                        320: {
                            slidesPerView: 1
                        }
                    }
                },
                swiperOptionsSale: {
                    slidesPerView: 4,
                    spaceBetween: 40,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    },
                    breakpoints: {
                        1024: {
                            slidesPerView: 3
                        },
                        768: {
                            slidesPerView: 3
                        },
                        640: {
                            slidesPerView: 1
                        },
                        320: {
                            slidesPerView: 1
                        },
                        10:{
                            slidesPerView: 1
                        }
                    }
                },
                currentModalItem: {
                    src: 'api/user/'
                },
                modalShow: false,
                currentIndex: 0,
                swipeStart: 0,
                swipeDistance: 0
            }
        },
        methods: {
            getKeyForSwiper(item) {
                if (this.type === 'imageGallery') {
                    return `thumbnail_${  item.itemId}`
                } else if (this.type === 'webShopGallery') {
                    return `thumbnail_${  item}`
                }
            },
            onSlideVerticalChangeEvent() {
                const swiper = this.$refs.swiperVerticalTop
                let onEnd = false
                let onBeg = false

                const current_width = window.innerWidth
                let current_slides_per_view = 1

                if (current_width > 1024) {
                    current_slides_per_view = swiper.swiperInstance.passedParams.breakpoints['1024'].slidesPerView
                } else if (current_width > 768) {
                    current_slides_per_view = swiper.swiperInstance.passedParams.breakpoints['768'].slidesPerView
                } else if (current_width > 640) {
                    current_slides_per_view = swiper.swiperInstance.passedParams.breakpoints['640'].slidesPerView
                } else if (current_width > 320) {
                    current_slides_per_view = swiper.swiperInstance.passedParams.breakpoints['320'].slidesPerView
                }

                if (swiper) {
                    const totalEle = this.swiperData.length
                    const currentEle = current_slides_per_view + swiper.swiperInstance.activeIndex

                    onEnd = currentEle === totalEle
                    onBeg = swiper.swiperInstance.activeIndex === 0
                }

                this.isSwiperVerticalOnEnd = onEnd
                this.isSwiperVerticalOnBeg = onBeg
            },
            onSlideChangeEvent() {
                const swiper = this.$refs.swiperTop
                let onEnd = false
                let onBeg = false

                const current_width = window.innerWidth
                let current_slides_per_view = 1

                if (current_width > 1024) {
                    current_slides_per_view = swiper.swiperInstance.passedParams.breakpoints['1024'].slidesPerView
                } else if (current_width > 768) {
                    current_slides_per_view = swiper.swiperInstance.passedParams.breakpoints['768'].slidesPerView
                } else if (current_width > 640) {
                    current_slides_per_view = swiper.swiperInstance.passedParams.breakpoints['640'].slidesPerView
                } else if (current_width > 320) {
                    current_slides_per_view = swiper.swiperInstance.passedParams.breakpoints['320'].slidesPerView
                }

                if (swiper) {
                    const totalEle = this.swiperData.length
                    const currentEle = current_slides_per_view + swiper.swiperInstance.activeIndex

                    onEnd = currentEle === totalEle
                    onBeg = swiper.swiperInstance.activeIndex === 0
                }
                
                this.isSwiperOnEnd = onEnd
                this.isSwiperOnBeg = onBeg
            },
            touchStart(event) {
                this.swipeStart = event.changedTouches[0]
            },
            detectSwipe(event) {

                const swipeEnd = event.changedTouches[0]
                if (Math.abs(this.swipeStart.pageX - swipeEnd.pageX) > 50) {
                    if (swipeEnd.pageX - this.swipeStart.pageX > 0) {
                        this.nextImage('left')
                    } else {
                        this.nextImage('right')
                    }
                } else {
                    const img = event.target

                    const cords = event.changedTouches[0]
                    const srcEle = { 
                        x: event.srcElement.clientHeight - event.srcElement.offsetLeft,
                        y: event.srcElement.clientWidth - event.srcElement.offsetLeft
                    }

                    if (img && img.tagName === 'IMG') {

                        if (srcEle.x / 2 <= cords.clientX) {
                            this.nextImage('right')
                        } else {
                            this.nextImage('left')
                        }
                     
                    }
                }
            },
            fullScreenImageVertical(item, index) {
                this.$refs.swiperVerticalTop.$swiper.slideTo(index)
                this.currentModalItem = item
                this.currentIndex = index
                this.modalShow = true
                document.documentElement.style.overflow = 'hidden'
            
                const blaz = document.getElementById('custom_fc_button')

                if (blaz) {
                    blaz.style.visibility = 'hidden'
                }
            },
            fullScreenImage(item, index) {
                this.$refs.swiperTop.$swiper.slideTo(index)
                this.currentModalItem = item
                this.currentIndex = index
                this.modalShow = true
                document.documentElement.style.overflow = 'hidden'

                document.documentElement.style.overflow = 'hidden'
            
                const blaz = document.getElementById('custom_fc_button')

                if (blaz) {
                    blaz.style.visibility = 'hidden'
                }
            },
            nextImage(side) {

                if (side === 'right') {
                    if (this.currentIndex >= this.swiperData.length - 1) {
                        this.currentIndex = 0
                    } else {
                        this.currentIndex++
                    }
                }
                if (side === 'left') {
                    if (this.currentIndex <= 0) {
                        this.currentIndex = this.swiperData.length - 1
                    } else {
                        this.currentIndex--
                    }
                }
                this.currentModalItem = this.swiperData[this.currentIndex]
            },
            onSlideChange(item) {
                this.currentModalItem = item
            },
            close() {
                this.modalShow = false
                document.documentElement.style.overflow = 'show'

                const blaz = document.getElementById('custom_fc_button')

                if (blaz) {
                    blaz.style.visibility = 'visible'
                }
            },
            closeGallery(event) {
                if (!(event.srcElement.localName === 'img' || event.srcElement.localName === 'svg' || event.srcElement.localName === 'polygon')) {
                    this.modalShow = false
                    document.documentElement.style.overflow = 'show'

                    const blaz = document.getElementById('custom_fc_button')

                    if (blaz) {
                        blaz.style.visibility = 'visible'
                    }
                }

                
            }

            
        },
        mounted() {
            let swiper = this.$refs.swiperVerticalTop

            const current_width = window.innerWidth
            let current_slides_per_view = 1
            
            if (swiper) {
                if (current_width > 1024) {
                    current_slides_per_view = swiper.swiperInstance.passedParams.breakpoints['1024'].slidesPerView
                } else if (current_width > 768) {
                    current_slides_per_view = swiper.swiperInstance.passedParams.breakpoints['768'].slidesPerView
                } else if (current_width > 640) {
                    current_slides_per_view = swiper.swiperInstance.passedParams.breakpoints['640'].slidesPerView
                } else if (current_width > 320) {
                    current_slides_per_view = swiper.swiperInstance.passedParams.breakpoints['320'].slidesPerView
                }

                const totalEle = this.swiperData.length

                if (totalEle < current_slides_per_view) {
                    this.swiperLessThanWidthPicVertical = true
                } else {
                    this.swiperLessThanWidthPicVertical = false
                }
            }

            swiper = this.$refs.swiperTop

            if (swiper) {
                if (current_width > 1024) {
                    current_slides_per_view = swiper.swiperInstance.passedParams.breakpoints['1024'].slidesPerView
                } else if (current_width > 768) {
                    current_slides_per_view = swiper.swiperInstance.passedParams.breakpoints['768'].slidesPerView
                } else if (current_width > 640) {
                    current_slides_per_view = swiper.swiperInstance.passedParams.breakpoints['640'].slidesPerView
                } else if (current_width > 320) {
                    current_slides_per_view = swiper.swiperInstance.passedParams.breakpoints['320'].slidesPerView
                }

                const totalEle = this.swiperData.length

                if (totalEle <= current_slides_per_view) {
                    this.swiperLessThanWidthPic = true
                } else {
                    this.swiperLessThanWidthPic = false
                }
            }
        },
        created() {
            const thisIns = this
            document.addEventListener('keyup', function(evt) {
                if (evt.key === 'Escape' && thisIns.modalShow === true) {
                    thisIns.close()
                }
            })
        }
    }
</script>

<style>

.swiper-container{
    position: relative;
}

</style>

<style scoped>
.button-next{
    background: red;
    width: 100px;
}

.card {
  box-shadow: none !important;
}
</style>

<style scoped>

/* FOR HIDING CONTENT IN SWIPER BUTTONS to have fading animation */
.hide-content{
    opacity: 0;
    transition: opacity 0.4s;
}

.show-content{
    opacity: 1;
    transition: opacity 0.4s;
}


.swiper-right-hide-padding{
    height: 500px;
    width: 20px;
    position: absolute;
    right: 0px;
    z-index: 101;
    background: white;
    pointer-events: none;
}

.swiper-left-hide-padding{
    height: 500px;
    width: 20px;
    position: absolute;
    left: 0px;
    z-index: 101;
    background: white;
    pointer-events: none;
}

.swiper-buttons-on-outside{
    right: -20px;
    left: -20px;
    width: calc(100% + 40px);
    padding-right: 20px;
    padding-left: 20px;
    z-index: 20 !important;
    position: relative;
}
@media only screen and (min-width: 768px) {
  .swiper-sale:after {
    border-right: 0.031rem solid #009ED3;
    content:"";
    position:absolute;
    top: 5rem;
    right:-5px;
    height: 250px;
  }
  .swiper-sale:last-child div::after {
    border-right: none;
    content:"";
    position:absolute;
    top: 5rem;
    right:-1px;
    height: 250px;
  }
}
</style>

<style lang="scss">
// SWIPER VERTICAL

.swiper-vertical-bottom-imageGallery{
    padding-bottom: 125px;
    transition: padding 0.75s;
}

.swiper-vertical-top-imageGallery{
    padding-top: 125px;
    transition: padding 0.75s;
}

.swiper-vertical-both-imageGallery{
    padding-bottom: 63px ;
    padding-top: 63px;
    transition: padding 1s;
}

.swiper-height-none{
    height: 0px !important;
    transition: height 1s;
}

.swiper-button-prev-vertical{
    position: absolute;
    width: 100%;
    height: 115px;
    background: #EAF3F9 !important;
    opacity: 1 !important;
    top: 0px;
    font-size: 3em;
    font-weight: bold;
    color: #72A5D8;
    z-index: 100;
    transition: height 0.75s;
}

.swiper-button-next-vertical{
  position: absolute;
  width: 100%;
  height: 116px;
  background: #EAF3F9 !important;
  opacity: 1 !important;
  bottom: 0px;
  font-size: 3em;
  font-weight: bold;
  color: #72A5D8;
  z-index: 100;
  transition: height 0.75s;
}

.swiper-button-prev-vertical-both {
  height: 54px;
  width: 100% !important;
  background: #EAF3F9 !important;
  opacity: 1 !important;
  top: 0;
  transition: height 1s;
}

.swiper-button-next-vertical-both {
  height: 54px;
  width: 100% !important;
  background: #EAF3F9 !important;
  opacity: 1 !important;
  bottom: 0;
  transition: height 1s;
}

@media only screen and (max-width: 768px){
    // .swiper-button-prev-both{
    //     width: calc(20% - 19px) !important;
    // }
    // .swiper-button-next-both{
    //     width: calc(20% - 19px) !important;
    // }

    .swiper-vertical-class{
        margin-top: 20px !important;
    }
    
 }

</style>

<style lang="scss">
// SWIPER HORIZONTAL

.navigation-swiper-right{
    padding-right: 25%;
    padding-left: 0px;
    transition: padding 0.75s;
}

.navigation-swiper-left{
    padding-left: 25%;
    padding-right: 0px;
    transition: padding 0.75s;
}

.navigation-swiper-both{
    padding-right: 12.5%;
    padding-left: 12.5%;
    transition: padding 0.75s;
}

.swiper-width-none{
    width: 0px !important;
    transition: width 0.75s;
}


.swiper-button-next-image-gallery {
    position: absolute;
  width: calc(25% - 19px);
  height: 100% !important;
  background: #EAF3F9 !important;
  opacity: 1 !important;
  top: 0;
  right: 0;
  font-size: 3em;
  font-weight: bold;
  color: #72A5D8;
  transition: width 0.75s;
  z-index: 100;
}

.swiper-button-prev-image-gallery {
    position: absolute;
  width: calc(25% - 19px);
  height: 100% !important;
  background: #EAF3F9 !important;
  opacity: 1 !important;
  top: 0;
  left: 0;
  transition: width 0.75s;
  font-size: 3em;
  font-weight: bold;
  color: #72A5D8;
  z-index: 100;
}

.swiper-button-prev-both {
  width: calc(12.5% - 19px);
  height: 100% !important;
  background: #EAF3F9 !important;
  opacity: 1 !important;
  top: 0;
  left: 0;
  transition: width 0.75s;
}

.swiper-button-next-both {
  width: calc(12.5% - 19px);
  height: 100% !important;
  background: #EAF3F9 !important;
  opacity: 1 !important;
  top: 0;
  right: 0;
}

.swiper-button-next-image-gallery::after {
    content: "";
}
.swiper-button-prev-image-gallery::after {
    content: "";
}


 @media only screen and (max-width: 640px){
    .swiper-button-prev-both{
        width: calc(20% - 19px) !important;
    }
    .swiper-button-next-both{
        width: calc(20% - 19px) !important;
    }
    
 }

</style>

<style lang="scss">
.swiper-pagination-bullet-active{
  background: #72a5d8 !important;
}


.swiper-pagination-bullet{
    background: #98bfe9;
    width: 17px;
    height: 17px;
    margin-left: 5px ;
    margin-right: 5px;
}

.swiper-pagination{
    // margin-top: 10px !important;
    position: relative;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    // align-items: end;
    height: 30px;
}

.card-img, .card-img-overlay, .card {
  border-radius: 0;
}

.swiper-button-next {
  width: 35px;
  top: 50%;
  right: 0;
  margin-left: 25%;
}

.swiper-button-prev {
  width: 35px;
  top: 50%;
  left: 0;
  margin-right: 25%;
}

.swiper-button-next::after {
    padding: 10px;
    height: 60px;
    font-size: 40px !important;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: white;
    background: white !important;
    color: black;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    z-index: 1100;
}
.swiper-button-prev::after {
    padding: 10px;
    height: 60px;
    font-size: 40px !important;
    -webkit-text-stroke-width: 1.5px;
    -webkit-text-stroke-color: white;
    // width: 100%;
    background: white !important;
    color: black;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    z-index: 1100;
}


.arrow {
  border: solid black;
  border-width: 0 7px 7px 0;
  display: inline-block;
  padding: 20px;
}

.text-bottom-right {
    padding: 10px;
    text-align: right;
    font-size: 1.5rem;
    // position: absolute;
    // right: 30px;
    // bottom: 10vh;
}

@media only screen and (min-width: 1200px) {
    .right {
        transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        position: absolute;
        right: 30px;
        top: 47vh;
        cursor: pointer;
    }

    .left{
        transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        position: absolute;
        left: 30px;
        top: 47vh;
        cursor: pointer;
    }
    .image-fullscreen-gallery{
        margin-top: 20px;
        max-height: 90vh;
        max-width: 80vw;
    }
}

@media only screen and (max-width: 1199px) {
    .image-fullscreen-gallery{
        // margin-top: 6em;
        max-height: 70vh;
        max-width: 95vw;
    }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/* Arrow & Hover Animation */

.more-arrows {
width: 75px;
height: 65px;

  &:hover {
    
    polygon {
      fill: #FFF;
      transition: all .2s ease-out;

      &.arrow-bottom {
        transform: translateY(-18px);
      }

      &.arrow-top {
        transform: translateY(18px);
      }
      
    }
  
  }
  
}

polygon {
  fill: #FFF;
  transition: all .2s ease-out;
    
  &.arrow-middle {
        opacity: 0.75;
    }

    &.arrow-top {
        opacity: 0.5
    }
}
</style>

<style lang="scss">
.close-btn {
  position: absolute;
  top: 10px;
  right: 20px;
  box-sizing: border-box;
  
  width: 4em;
  height: 4em;

  color: transparent;
  cursor: pointer;
  transition: all ease 0.3s;
  
  &:before, &:after {
    position: absolute;
    width: 3em;
    height: 6px;
    left: 8px;
    top: 24px;

    content: '';
    background: #d6d7d8;
    transition: all ease 1s;
  }
  
  &:before {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  
  &:after {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
  &:hover, &:focus {
    transform: scale(1.2);

    &:before, &:after {
      background: rgb(245, 149, 149);
    }
  }
}
</style>
