<template>
    <div v-if="realEstate" class="position-relative" v-on:click="redirectToSingleRealEstate" style="cursor: pointer">
        <div class="badge pl-1" :style="[all === true ? {'position': 'absolute !important', 'top': '-17px !important'}:{}]">{{ setListingType(realEstate.listing_type) }}</div>
        <div class="over-image" :style="[all ? {'top': '0 !important'}:{}]">
            <div class="title">{{realEstate.title}}</div>
        </div>
        <div class="card-content">
            <router-link :to="{ name: 'real-estate', params: {real_estate_id: realEstate.id, realEstateTitle: realEstate.title}}">
                <div class="location">{{realEstate.location.toUpperCase()}}</div>
            </router-link>
            <div class="mt-auto pt-1 d-flex justify-content-between align-items-center">
                <router-link :to="{ name: 'real-estate', params: {real_estate_id: realEstate.id, realEstateTitle: realEstate.title}}">
                    > več o tem
                </router-link>
                <div v-if="all && !realEstate.price_per" class="price">{{$convertPriceIntegerToFloat(realEstate.price)}} €</div>
                <div v-else-if="all && realEstate.price_per" class="price">{{$convertPriceIntegerToFloat(realEstate.price)}} €/m<sup>2</sup></div>
            </div>
        </div>
        <b-img v-if="realEstate.main_photo" class="main-photo" :src="`/api/user/v1/image/medium_${realEstate.main_photo}`"/>
    </div>
</template>

<script>
    import {BImg} from 'bootstrap-vue'
    export default
        {
            components: {
                BImg
            },
            props: {
                realEstate: {
                    type: Object,
                    required: true
                },
                all: {
                    type: Boolean,
                    default: false
                }
            },
            methods: {
                redirectToSingleRealEstate() {
                    this.$router.push({ name: 'real-estate', params: {real_estate_id: this.realEstate.id, realEstateTitle: this.realEstate.title}})
                },
                setListingType(type) {
                    if (type === 0) return 'PRODAJA'
                    else if (type === 1) return 'ODDAJA'
                    else if (type === 2) return 'NAJEM'
                    else if (type === 3) return 'ZAMENJAVA'
                    else return ''
                }
            }
        }
</script>

<style scoped>
.badge {
  border-radius: 0 !important;
  position: sticky;
  background-color: #72A5D8;
  top: 15px;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  padding-right: 1.5rem;
  clip-path: polygon(0 0, 0 100%, 100% 100%, 93% 100%, 100% 0);
  z-index: 2;
}
.over-image{
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
  position: absolute;
  top: 2.4rem;
  left: 0;
  width: 100%;
  padding: 1.8rem;
  z-index: 1;
}
.title {
  font-weight: 700;
  font-size: 33px;
  line-height: 41px;
  color: #FFFFFF;
  padding-bottom: 2px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
}
.location {
  font-weight: 400;
  font-size: 17px;
  line-height: 23px;
  color: black;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
}
a {
  font-weight: 700;
  font-size: 17px;
  line-height: 21px;
  color: #72A5D8;
}
.card-content{
  background: rgba(255, 255, 255, 0.88);
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  padding: 0.8rem 1.8rem;
}
.main-photo {
  width: 100%;
  height: 358px;
  object-fit: cover;
}
.price {
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
}
</style>